<template>
<div class="serve" >
 <Header url="serve" />
<div class="head">
      <div class="stitle">7x24小时服务</div>
      <div class="stitle2">追求卓越 尽善尽美</div>

      <div class='span'>
                <span>品质</span>  
                <span>专业</span>  
                <span>省心</span>  
      </div>
</div>

    <div class="servebox">
          <div class="w12 clearfix">
                <div class="item2 " v-for="(item,index) in serve" :key="index">
                      <div class="p20">
                          <img  class="serveimg" :src="item.img" alt="">
                          <div class="sittitle">{{item.title}}</div>
                          <div class="sittitle2">{{item.desc}}</div>    
                      </div>    
                </div>
            
          </div>
   </div>
  <Footer />
  </div>
</template>

<script>
import '../../assets/css/index.css'
import './index.css'

import Header from '../../components/header'

import Footer from '../../components/footer'
import {getserve} from '../../request/api'
export default {
    name: 'serve',
    components: {
        Header,
        Footer,
    },
    data() {
      return {
          serve:[]
      }
    },
    mounted(){
             this.getserveDate();
    },
    methods: {
        getserveDate(){
            let that = this;
            getserve().then(res=>{
                 that.serve = res.data
            })

        }
    }


}
</script>

